import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  findChangedObject,
  getObjectDiffs,
  flattenObjectives,
  areObjectivesEqual
} from "./utils";
import {
  Objective,
  EMPTY_OBJECTIVE
} from "@/components/app/Objectives/types";
import JwtService from "@/core/services/JwtService";
import { sendMautic } from "@/core/plugins/mautic";

interface DiffObject {
  key: string;
  oldValue: any;
  newValue: any;
}

interface DiffElement {
  id: number;
  diff: DiffObject[];
}
interface SyncTitle {
  title: string;
  type: string;
}

@Module
export default class ObjectiveModule extends VuexModule {
  expand = false;
  percentage = false;
  loadingObjective = false;
  fromChange = false;
  selectedUser = 0;
  syncTitle: SyncTitle = {
    title: "",
    type: ""
  };
  changedObjectElement: DiffElement | null = null;
  changedObjectBool = false;
  selectedObjective = { ...EMPTY_OBJECTIVE };
  savedPrevObjective = { ...EMPTY_OBJECTIVE };
  objectives = [];
  flatObjectives: Objective[] = [];
  currentIndex = 0;
  ObjectivesLoaded = false;
  cycle = {};
  isModalOpen = false;
  cycles = [];

  get getExpand(): boolean {
    return this.expand;
  }

  get getPercentage(): boolean {
    return this.percentage;
  }

  get getFromChange(): boolean {
    return this.fromChange;
  }

  get getSelectedUser(): number {
    return this.selectedUser;
  }

  get getSelectedObjective(): Objective {
    return this.selectedObjective;
  }

  get getSelectedObjectiveSyncTitle(): SyncTitle {
    return this.syncTitle;
  }

  get getLoadingObjective(): boolean {
    return this.loadingObjective;
  }

  //TODO modificar desde le back
  get getSelectedObjectiveCamelCase(): Objective {
    return Object.keys(this.selectedObjective).reduce((acc, key) => {
      const newKey = key.replace(/_\w/g, m => m[1].toUpperCase());
      return { ...acc, [newKey]: this.selectedObjective[key] };
    }, {}) as Objective;
  }

  get getSavedPrevObjective(): Objective {
    return this.savedPrevObjective;
  }

  get getObjectives(): Array<{}> {
    return this.objectives;
  }

  get getChangedObjectElement(): DiffElement | null {
    return this.changedObjectElement;
  }

  get getChangedObjectBool(): boolean {
    return this.changedObjectBool;
  }

  get getObjectivesLoaded(): boolean {
    return this.ObjectivesLoaded;
  }

  get getCycle(): {} {
    return this.cycle;
  }

  get getAllCycles(): Array<{}> {
    return this.cycles;
  }

  // Action

  @Action
  [Actions.OPEN_CYCLE]() {
    this.context.commit(Mutations.SET_OPEN_CYCLE);
  }

  @Action
  [Actions.UPDATE_OBJECTIVE](objective: Objective) {
    const currentToken = JwtService.getToken();
    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs`;

    const updateObj = {
      code: objective.code,
      currentValue: objective.currentValue,
      description: objective.description,
      differentiatedStart: objective.differentiatedStart,
      earlyEnding: objective.earlyEnding,
      frequency: objective.frequency,
      id: objective.id,
      organization: objective.organization,
      parent: objective.parent,
      period: objective.period,
      startValue: objective.startValue,
      targetValue: objective.targetValue,
      title: objective.title,
      type: objective.type,
      unit: objective.unit,
      user: objective.user.id
    };

    if (currentToken) {
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(updateObj)
      })
        .then(res => {
          res.json();
          this.context.dispatch(Actions.LOAD_OBJECTIVE, {
            objective: null,
            payload: true
          });
        })
        .catch(error => {
          console.log(`error`, error);
        });
    }
  }

  @Action
  [Actions.SAVE_OBJECTIVE](objective: Objective) {
    const currentToken = JwtService.getToken();
    const organizationId = this.context.getters.getCurrentOrganizationId;
    const owner = this.context.getters.currentUser.id;
    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs`;

    const createObj: object = {
      ...objective,
      user: objective.user.id,
      subObjectives: [],
      organization: organizationId,
      owner
    };

    if (currentToken) {
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(createObj)
      })
        .then(res => {
          res.json();
          this.context.dispatch(Actions.LOAD_OBJECTIVE, {
            objective: null,
            payload: true
          });
          this.context.dispatch(Actions.RESTORE_PREV_OBJECTIVE);
        })
        .catch(error => {
          console.log(`error`, error);
        });
    }
  }

  @Action
  [Actions.REMOVE_OBJECTIVE](objective: Objective) {
    const currentToken = JwtService.getToken();
    if (currentToken) {

      const currentToken = JwtService.getToken();
      const organizationId = this.context.getters.getCurrentOrganizationId;
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/` + objective.id + "/" + organizationId;

      if (currentToken) {
        return fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": currentToken,
          },
        })
          .then(res => {
            res.json();
            this.context.dispatch(Actions.LOAD_OBJECTIVE, {
              objective: null,
              payload: true
            });
          })
          .catch(error => {
            console.log(`error`, error);
          });
      }
    }
  }

  @Action
  [Actions.CHANGE_SELECTED_USER](payload) {
    this.context.commit(Mutations.SET_SELECTED_USER, payload);
  }

  @Action
  [Actions.CHANGE_SELECTED_OBJECTIVE](payload: Objective) {
    const index = this.flatObjectives.findIndex(
      objective => objective.id === payload.id
    );
    if (index !== -1) {
      this.context.commit(Mutations.SET_CURRENT_INDEX, index);
      this.context.commit(Mutations.SET_SELECTED_OBJECTIVE, payload);
    }
  }

  @Action
  [Actions.ADD_SUBOBJECTIVE](payload: Objective) {
    const index = this.flatObjectives.findIndex(
      objective => objective.id === payload.id
    );
    if (index !== -1) {
      this.context.commit(Mutations.SET_CURRENT_INDEX, index);
      this.context.commit(Mutations.SET_SELECTED_OBJECTIVE, {
        ...EMPTY_OBJECTIVE,
        parent: payload.parent,
        id: payload.id,
        add: true,
        addTitle: payload.title
      });
    }
  }

  @Action
  [Actions.PREV_OBJECTIVE](payload: Objective) {
    this.context.commit(Mutations.STORE_PREV_OBJECTIVE, payload);
  }

  @Action
  [Actions.RESTORE_PREV_OBJECTIVE]() {
    this.context.commit(
      Mutations.SET_SELECTED_OBJECTIVE,
      this.savedPrevObjective
    );
  }

  @Action
  [Actions.RESET_SELECTED_OBJECTIVE]() {
    this.context.commit(Mutations.SET_EMPTY_SELECTED_OBJECTIVE);
  }

  @Action
  [Actions.LOAD_OBJECTIVE]({ objective, payload = false }) {
    if (payload) {
      this.context.commit(Mutations.SET_LOAD_OBJECTIVE, true);
      const currentToken = JwtService.getToken();
      const organizationId = this.context.getters.getOrganization[0].id;
      const periodId = this.context.getters.getCycle.id;
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/organization/` +
        organizationId +
        "/" +
        periodId;

      if (currentToken) {
        return fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": currentToken
          }
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            const { result: objectiveList } = data;
            this.context.commit(Mutations.SET_OBJECTIVE, { objective: objectiveList, socketChange: false });
            //socket mando los cambios realizados al objetivo si se guardaron correctamente a los usuarios conectados
            this.context.dispatch(Actions.SEND_CHANGE_OBJECTIVE, objectiveList);
            this.context.commit(Mutations.SET_LOAD_OBJECTIVE, false);
            sendMautic(this.context, currentToken)
          })
          .catch(error => {
            console.log(`error`, error);
          });
      }
    } else {
      this.context.commit(Mutations.SET_OBJECTIVE, { objective, socketChange: false });
      //this.context.dispatch(Actions.SEND_CHANGE_OBJECTIVE, objective);
    }
  }

  @Mutation
  [Mutations.SET_LOAD_OBJECTIVE](payload: boolean) {
    this.loadingObjective = payload;
  }

  @Action
  [Actions.LOADED_OBJECTIVE](payload) {
    this.context.commit(Mutations.SET_LOADED_OBJECTIVE, payload);
  }

  @Action
  [Actions.CHANGE_EXPAND](expand: boolean) {
    this.context.commit(Mutations.SET_EXPAND, expand);
  }

  @Action
  [Actions.CHANGE_PERCENTAGE](payload) {
    this.context.commit(Mutations.SET_PERCENTAGE, payload);
  }

  @Action
  [Actions.CHANGE_CYCLE](payload) {
    this.context.commit(Mutations.SET_CYCLE, payload);
  }

  @Action
  [Actions.FETCH_CYCLES](payload) {
    this.context.commit(Mutations.SET_CYCLES, payload);
  }

  @Action
  [Actions.NEXT_OBJECTIVE]() {
    if (this.currentIndex < this.flatObjectives.length - 1) {
      this.context.commit(Mutations.SET_CURRENT_INDEX, this.currentIndex + 1);
      this.context.commit(
        Mutations.SET_SELECTED_OBJECTIVE,
        this.flatObjectives[this.currentIndex]
      );
    }
  }

  @Action
  [Actions.IS_EQUAL](payload: Objective) {
    const result = areObjectivesEqual(payload, this.selectedObjective);
    this.context.commit(Mutations.SET_IS_EQUAL, !result);
  }

  @Action
  [Actions.RESET_EQUAL](payload: boolean) {
    this.context.commit(Mutations.SET_IS_EQUAL, payload);
  }

  @Action
  [Actions.PREVIOUS_OBJECTIVE]() {
    if (this.currentIndex > 0) {
      this.context.commit(Mutations.SET_CURRENT_INDEX, this.currentIndex - 1);
      this.context.commit(
        Mutations.SET_SELECTED_OBJECTIVE,
        this.flatObjectives[this.currentIndex]
      );
    }
  }

  @Action
  [Actions.SYNC_OBJECTIVE_TITLE](payload: SyncTitle) {
    this.context.commit(Mutations.SET_SYNC_OBJECTIVE_TITLE, payload);
  }

  // Mutation

  @Mutation
  [Mutations.SET_CURRENT_INDEX](index: number) {
    this.currentIndex = index;
  }

  @Mutation
  [Mutations.SET_FLAT_OBJECTIVES](objectives: Objective[]) {
    this.flatObjectives = objectives;
  }

  @Mutation
  [Mutations.SET_SELECTED_USER](payload) {
    this.selectedUser = payload;
  }

  @Mutation
  [Mutations.SET_OPEN_CYCLE]() {
    this.isModalOpen = !this.isModalOpen;
  }

  @Mutation
  [Mutations.SET_IS_EQUAL](payload: boolean) {
    this.fromChange = payload;
  }

  @Mutation
  [Mutations.SET_SELECTED_OBJECTIVE](payload: Objective) {
    this.selectedObjective = payload;
    this.syncTitle = { title: payload.title, type: payload.type };
  }

  @Mutation
  [Mutations.SET_EMPTY_SELECTED_OBJECTIVE]() {
    this.selectedObjective = EMPTY_OBJECTIVE;
  }

  @Mutation
  [Mutations.STORE_PREV_OBJECTIVE](payload: Objective) {
    this.savedPrevObjective = payload;
  }

  @Mutation
  [Mutations.SET_LOADED_OBJECTIVE](payload) {
    // this.objectives = [];
    this.ObjectivesLoaded = payload;
  }

  @Mutation
  [Mutations.SET_OBJECTIVE](payload) {
    try {
      const flatObj = flattenObjectives(payload.objective);
      this.flatObjectives = flatObj;
      const objs = findChangedObject(this.objectives, payload.objective);
      const [obj1, obj2] = objs ? objs : [null, null];
      if (obj1 || obj2) {
        const diffObj = getObjectDiffs(obj1, obj2);
        this.changedObjectElement = diffObj;
        this.changedObjectBool = !this.changedObjectBool;
        if (!payload.socketChange) {
          this.selectedObjective = flatObj.find(
            obj1 => obj1.id == diffObj.id
          ) as Objective;
        }
      }
    } catch (error) {
      // console.error(error);
    }
    this.objectives = payload.objective;
    this.ObjectivesLoaded = true;
  }

  @Mutation
  [Mutations.SET_EXPAND](expand = !this.expand) {
    this.expand = expand;
  }

  @Mutation
  [Mutations.SET_PERCENTAGE](payload) {
    this.percentage = payload;
  }

  @Mutation
  [Mutations.SET_CYCLE](payload) {
    this.cycle = payload;
  }

  @Mutation
  [Mutations.SET_SYNC_OBJECTIVE_TITLE](payload: SyncTitle) {
    this.syncTitle = { title: payload.title, type: payload.type };
  }
  @Mutation
  [Mutations.SET_CYCLES](payload) {
    this.cycles = payload;
    const actualDaTe = new Date().getTime();
    const period = payload.filter(cycle => {
      if (
        actualDaTe > new Date(cycle.start_at).getTime() &&
        actualDaTe < new Date(cycle.end_at).getTime()
      ) {
        return cycle;
      }
    });
    if (period && period.length) {
      this.cycle = period[0];
    } else {
      this.cycle = payload[payload.length - 1];
    }
  }
}
