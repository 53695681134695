import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  firstName: string;
  surname: string;
  lastName: string;
  email: string;
  password: string;
  token: string | null;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  async [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.errors = [];
    if (user === undefined) {
      return;
    }
    this.user = { ...user };

    if (!this.user.token) {
      return;
    }
    JwtService.saveToken(this.user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    const loginUrl = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/authenticate`;
    return new Promise<void>((resolve, reject) => {
      return fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials)
      })
        .then(res => {
          if (res.status === 404) {
            throw new Error("badCredentials");
          }
          return res.json();
        })
        .then(data => {
          const { result: userData } = data;
          this.context.commit(Mutations.SET_AUTH, userData);
          resolve(userData);
        })
        .catch(error => {
          console.log("[error] in AuthModule.ts", error);
          this.context.commit(
            Mutations.SET_ERROR,
            error.response?.data?.errors
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SETUP_USER](payload) {
    this.context.commit(Mutations.SET_AUTH, payload);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](userData) {
    const registerUrl = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users`;
    const { email, name, surname, password } = userData;
    const registerUserData = {
      email: email,
      name: name,
      lastName: surname,
      password: password
    };

    return new Promise<void>((resolve, reject) => {
      return fetch(registerUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(registerUserData)
      })
        .then(res => {
          if (res.status && (res.status === 404 || res.status === 401)) {
            throw new Error("badCredentials");
          } else if (res.status && res.status === 400) {
            throw new Error("serverError");
          }
          return res.json();
        })
        .then(data => {
          const { result: userData } = data;
          console.log(`------< userData`, userData);
          this.context.commit(Mutations.SET_AUTH, userData);
          resolve(userData);
        })
        .catch(error => {
          console.log("[error] in AuthModule.ts", error);
          this.context.commit(
            Mutations.SET_ERROR,
            error.response?.data?.errors
              ? error.response.data.errors
              : ["somethingWentWrong"]
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const { email } = payload;
    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/recovery-password?email=${email}`;

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 401) {
          throw new Error("resourceNotFound");
        }

        return res.json();
      })
      .then(data => {
        console.log("[data]", data);
        return {
          success: true
        };
      })
      .catch(error => {
        console.log("[error]", error);
        this.context.commit(Mutations.SET_ERROR, ["somethingWentWrong"]);
      });
  }

  @Action
  [Actions.ACTIVATE_ACCOUNT](payload) {
    console.log("[payload of recovery password here?]", payload);
    const { token } = payload;
    const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/validate?token=${token}`;

    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 401) {
          throw new Error("resourceNotFound");
        }

        return res.json();
      })
      .then(data => {
        const { result: user } = data;

        this.context.commit(Mutations.SET_AUTH, user);

        return {
          success: true,
          user: user
        };
      })
      .catch(error => {
        console.log("[error]", error);
        this.context.commit(Mutations.SET_ERROR, ["somethingWentWrong"]);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    const token = JwtService.getToken();

    if (token) {
      ApiService.setHeader();

      // @todo:
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/me`;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      })
        .then(res => {
          if (res.status === 401) {
            throw new Error("unauthorized");
          }

          return res.json();
        })
        .then(data => {
          const { result: user } = data;
          this.context.commit(Mutations.SET_AUTH, user);
        })
        .catch(error => {
          console.log("[error]", error);
          this.context.commit(Mutations.SET_ERROR, ["somethingWentWrong"]);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
