import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import JwtService from "@/core/services/JwtService";

@Module
export default class ObjectiveModule extends VuexModule {
  organization = null;
  currentOrganizationId = 0;
  organizations = [];

  get getOrganization(): {} | null {
    return this.organization;
  }

  get getCurrentOrganizationId(): number {
    return this.currentOrganizationId;
  }

  get getOrganizations(): Array<{}> {
    return this.organizations;
  }

  // Action
  @Action
  [Actions.LOAD_ORGANIZATION](payload) {
    const { id, organization } = payload;
    const currentToken = JwtService.getToken();
    if (currentToken != null && organization == null && id != null) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/${id}`;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401) {
            throw new Error("resourceNotFound");
          }

          return res.json();
        })
        .then(data => {
          this.context.commit(Mutations.SET_ORGANIZATION, data.result);
          return data.result[0];
        })
        .catch(error => {
          console.log("[error]", error);
        });
    } else {
      this.context.commit(Mutations.SET_ORGANIZATION, [organization]);
    }
  }

  @Action
  [Actions.FETCH_ORGANIZATIONS](payload) {
    this.context.commit(Mutations.SETEO_ORGANIZATIONS, payload);
  }
  // Mutation
  @Mutation
  [Mutations.SET_ORGANIZATION](payload) {
    this.organization = payload;
    this.currentOrganizationId = payload[0].id;
  }

  @Mutation
  [Mutations.SETEO_ORGANIZATIONS](payload) {
    this.organizations = payload;
  }
}
