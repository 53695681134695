enum Actions {
  // socket
  CONNECT_SOCKET = "CONNECT_SOCKET",
  SOCKET_JOIN = "SOCKET_JOIN",
  SEND_CHANGE_OBJECTIVE = "SEND_CHANGE_OBJECTIVE",
  SEND_CHANGE_DESCRIPTION = "SEND_CHANGE_DESCRIPTION",
  // app
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  SETUP_USER = "setupUser",
  FORGOT_PASSWORD = "forgotPassword",
  ACTIVATE_ACCOUNT = "activateAccount",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  CHANGE_EXPAND = "changeExpand",
  CHANGE_PERCENTAGE = "changePercentage",
  //objective
  LOAD_OBJECTIVE = "loadObjective",
  UPDATE_OBJECTIVE = "UPDATE_OBJECTIVE",
  SAVE_OBJECTIVE = "SAVE_OBJECTIVE",
  REMOVE_OBJECTIVE = "REMOVE_OBJECTIVE",
  //
  LOADED_OBJECTIVE = "loadedObjectives",
  SYNC_OBJECTIVE_TITLE = "SYNC_OBJECTIVE_TITLE",
  // Cycle manager
  CHANGE_CYCLE = "changeCycle",
  FETCH_CYCLES = "fetchCycles",
  //organization
  LOAD_ORGANIZATION = "loadOrganization",
  FETCH_ORGANIZATIONS = "cargaOrganizations",
  //tasks
  FETCH_TASKS = "fetchTasks",
  //SELECTEDUSER
  CHANGE_SELECTED_USER = "changeSelectedUser",
  CHANGE_SELECTED_OBJECTIVE = "changeSelectedObjective",
  ADD_SUBOBJECTIVE = "AddsUBObjective",
  RESTORE_PREV_OBJECTIVE = "RESTORE_PREV_OBJECTIVE",
  PREV_OBJECTIVE = "PREV_OBJECTIVE",
  RESET_SELECTED_OBJECTIVE = "ResetSelectedObjective",
  //Members
  FETCH_MEMBERS = "fetchMembers",
  LOAD_MEMBERS = "loadMembers",
  //Cycle
  OPEN_CYCLE = "openCycle",
  FETCH_PERIOD = "FETCH_PERIOD",
  SAVE_PERIOD = "SAVE_PERIOD",
  //notes
  FETCH_NOTES = "FETCH_NOTES",
  //notes
  FETCH_LOGS = "FETCH_LOGS",
  ADD_NOTE = "ADD_NOTE",
  EDIT_NOTE = "EDIT_NOTE",
  DELETE_NOTE = "DELETE_NOTE",

  //notes
  FETCH_COMMITMENTS = "FETCH_COMMITMENTS",
  FETCH_CHANGE_HEIGHT = "FETCH_CHANGE_HEIGHT",
  // commitments
  ADD_COMMITMENT = "ADD_COMMITMENT",
  EDIT_COMMITMENT = "EDIT_COMMITMENT",
  EDIT_COMMITMENT_CHECK = "EDIT_COMMITMENT_CHECK",
  DELETE_COMMITMENT = "DELETE_COMMITMENT",

  // navigation
  NEXT_OBJECTIVE = "NEXT_OBJECTIVE",
  IS_EQUAL = "IS_EQUAL",
  RESET_EQUAL = "RESET_EQUAL",
  PREVIOUS_OBJECTIVE = "PREVIOUS_OBJECTIVE"
}
enum Mutations {
  // socket
  SET_SOCKET = "SET_SOCKET",
  SET_CONNECTED = "SET_CONNECTED",
  SET_ROOM = "SET_ROOM",
  SET_UPDATE_FROM_SOCKET = "SET_UPDATE_FROM_SOCKET",

  // app
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  SET_IS_EQUAL = "SET_IS_EQUAL",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  // Cycle manager
  SET_CYCLE = "setCycle",
  SET_CYCLES = "setCycles",
  //objetive modulo
  SET_LOAD_OBJECTIVE = "SET_LOAD_OBJECTIVE",
  SET_EXPAND = "setExpand",
  SET_PERCENTAGE = "setPercentage",
  SET_OBJECTIVE = "setObjective",
  SET_SYNC_OBJECTIVE_TITLE = "SET_SYNC_OBJECTIVE_TITLE",
  SET_LOADED_OBJECTIVE = "setLoadedObjectives",
  //organization
  SET_ORGANIZATION = "setOrganization",
  SETEO_ORGANIZATIONS = "seteoOrganizations",
  //tasks
  SET_TASKS = "setTasks",
  //SELECTEDUSER
  SET_SELECTED_USER = "setSelectedUser",
  SET_SELECTED_OBJECTIVE = "setSelectedObjective",
  SET_EMPTY_SELECTED_OBJECTIVE = "setEmptySelectedObjective",
  STORE_PREV_OBJECTIVE = "STORE_PREV_OBJECTIVE",
  //Members
  SET_MEMBERS = "setMembers",
  //Cycle
  SET_OPEN_CYCLE = "setOpenCycle",
  SET_PERIOD = 'SET_PERIOD',
  SET_CHANGING_STATE = 'SET_CHANGING_STATE',
  //notes
  SET_NOTES = "SET_NOTES",
  SET_NOTE_FETCHING = "SET_NOTE_FETCHING",
  //logs
  SET_LOGS = "SET_LOGS",
  SET_LOGS_FETCHING = "SET_LOGS_FETCHING",
  //COMMITMENTS
  SET_COMMITMENTS = "SET_COMMITMENTS",
  SET_COMMITMENTS_FETCHING = "SET_COMMITMENTS_FETCHING",
  //COMMITMENTS
  SET_CHANGE_HEIGHT = "SET_CHANGE_HEIGHT",
  SET_EDIT_COMMITMENT = "SET_EDIT_COMMITMENT",
  SET_ADD_COMMITMENT = "SET_ADD_COMMITMENT",

  SET_CURRENT_INDEX = "SET_CURRENT_INDEX",
  SET_FLAT_OBJECTIVES = "SET_FLAT_OBJECTIVES"
}
export { Actions, Mutations };
