import { Objective } from "../../components/app/Objectives/types";
import isEqual from "lodash/isEqual";

interface MyObject {
  id: number;
  name: string;
  value: number;
  subObjectives?: MyObject[];
}

interface DiffObject {
  key: string;
  oldValue: any;
  newValue: any;
}

interface DiffElement {
  id: number;
  diff: DiffObject[];
}

export function findChangedObject(
  oldValues: MyObject[],
  newValues: MyObject[]
): [MyObject, MyObject | undefined] | null {
  const idToObjectMap = new Map<number, MyObject>();
  for (const obj of newValues) {
    idToObjectMap.set(obj.id, obj);
  }
  for (let i = 0; i < oldValues.length; i++) {
    const obj1 = oldValues[i];
    const obj2 = idToObjectMap.get(obj1.id);
    if (!obj2) {
      return null;
    }
    if (obj1.subObjectives && obj1.subObjectives.length > 0) {
      const objs = findChangedObject(
        obj1.subObjectives,
        obj2.subObjectives || []
      );
      if (objs) {
        return objs;
      }
    }
    const keys = Object.keys(obj1);
    for (const key of keys) {
      const value1 = obj1[key];
      const value2 = obj2[key];
      if (key === "user") {
        if (value1.id !== value2.id) {
          return [obj1, obj2];
        }
      } else if (typeof value1 === "object" && value1 !== null) {
        const objs = findChangedObject([value1], [value2]);
        if (objs) {
          return objs;
        }
      } else if (value1 !== value2) {
        return [obj1, obj2];
      }
    }
  }
  return null;
}

export function getObjectDiffs(
  obj1: any,
  obj2: any,
  diffs: DiffObject[] = []
): DiffElement {
  const keys = Object.keys(obj1);
  keys.forEach(key => {
    if (key === "edited_at" || key === "subObjectives") {
      return;
    }
    const desc = Object.getOwnPropertyDescriptor(obj1, key);
    if (desc) {
      const value1 = desc.value;
      const value2 = obj2[key];
      if (key === "user") {
        if (value1.id !== value2.id) {
          diffs.push({
            key,
            oldValue: value1,
            newValue: value2
          });
        }
      } else if (typeof value1 === "object" && value1 !== null) {
        getObjectDiffs(value1, value2, diffs);
      } else if (value1 !== value2) {
        diffs.push({
          key,
          oldValue: value1,
          newValue: value2
        });
      }
    }
  });
  return { id: obj1.id, diff: diffs };
}

export function flattenObjectives(
  objectives: Objective[],
  list: Objective[] = []
): Objective[] {
  for (const objective of objectives) {
    list.push(objective);
    if (objective.subObjectives.length > 0) {
      flattenObjectives(objective.subObjectives, list);
    }
  }
  return list;
}

export function areObjectivesEqual(obj1: Objective, obj2: Objective) {
  return isEqual(obj1, obj2);
}

export function findObjectById(
  objectives: Objective[],
  list: Objective[] = []
): Objective[] {
  for (const objective of objectives) {
    list.push(objective);
    if (objective.subObjectives.length > 0) {
      flattenObjectives(objective.subObjectives, list);
    }
  }
  return list;
}
