import { createI18n } from "vue-i18n";

const messages = {
  en: {
    addCommitment: "Add a commitment...",
    addNote: "Add a note...",
    alreadyInUse: "Email is already in use.",
    organizationSize: {
      Micro: "Micro",
      Small: "Small",
      Medium: "Medium",
      Large: "Large",
      Corporate: "Corporate"
    },
    forwardedMail: "The email was resent",
    here: "here",
    //invite to participate email
    invitationMessages: {
      hi: "Hi",
      notRespond:
        "IMPORTANT: This is an automatically generated email, do not reply to it.",
      ifNotExist:
        "The account was automatically generated, change your password by doing",
      ifExist: "Respond to the invitation by",
      click: "click here",
      subject: "You were invited to be part of an organization",
      editAccount: "Edit your account",
      changeData: "The account is generated automatically, change your data"
    },
    //yup
    isRequired: "is a required field",
    validEmail: "must be a valid email",
    validPhone: "must be a valid phone number",
    Accept: "Accept",
    minUppercase: "must contain at least 1 uppercase letter",
    minSymbols: "must contain at least 1 symbol",
    minNumbers: "must contain at least 1 number",
    min: "must be at least 8 characters",
    //
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountData: "Account Data",
    myAccount: "My Account",
    accountOverview: "Overview",
    settings: "Settings",
    userData: "User Data",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign in",
    signUp: "Create a Free Account",
    signInSuggestion: "Already have an account?",
    signUpSuggestion: "You are new?",
    passwordReset: "Password Reset",
    badPassword:
      "Wrong password. Try again or click Forgot password to reset it.",
    tryAgain: "Try again",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    createOrganization: "Create Organization",
    createObjectiveButton: "Create Highest Impact Goal - OMI",
    createObjective: "Create Highest Impact Goal",
    english: "English",
    spanish: "Spanish",
    language: "Language",
    users: "Users",
    description: "Description",
    // modals
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create your Free Account",
    activity: "Activity",
    accomplished: "Accomplished",
    manage: "Manage",
    previousVersion: "Previous Version",
    cycle: "Cycle",
    createCycle: "Create Cycle",
    createApiKey: "Create API key",
    manageCycle: "Create or edit OKR cycles for your organization",
    manageApiKey: "Create or remove API keys for your organization",
    cycleManager: "Cycle Manager",
    apiKeyManager: "API Key Manager",
    doYouNeedHelp: "Need help?",
    organizations: "Organizations",
    myOrganizations: "My Organizations",
    myReports: "My Reports",
    organization: "Organization",
    organizationCreatedAt: "Created At",
    organizationOwner: "Organization Owner",
    organizationUserQuantity: "Quantity of Users",
    organizationEdition: "Organization Edition",
    organizationSettings: "Organization Settings",
    organizationLeaving: "Organization Leaving",
    createdOn: "Created on",
    lastEditedOn: "Last edited on",
    uploaderFor: "Upload XLSX file for",
    organizationLeavingSuccessfully: "Organization was leave successfully",
    organizationSettingsIntro:
      "Here you can manage general information about the organization.",
    organizationSettingsLeaving:
      "You are about of leaving this organization. Are you sure?",
    organizationPublicMembers: "Public users inside the organization",
    organizationShareStatistic:
      "Anonymous sharing of my information for statistical purposes",
    organizationLeave: "Leave",
    organizationCancel: "Cancel",
    organizationReason: "Organization Reason",
    organizationStartup: "My organization is a Startup",
    edit: "Edit",
    back: "back",
    save: "Save",
    send: "Send",
    sending: "Sending...",
    cancel: "Cancel",
    confirm: "Confirm",
    absolute: "At the end of the cycle",
    relative: "Today",
    progress: "Progress",
    byName: "By Name",
    byGroup: "By Group",
    byCompany: "By Company",
    groupsAndMembers: "Groups and Users",
    download: "Download XLSX file",
    loadFromFile: "Load from XLSX file",
    elapsed: "Elapsed",
    Planned: "Planned",
    targetsNotFound: "Targets not found",
    terminology: "Terminology",
    turnOff: "Go Out",
    manager: "Manager",
    members: "Users",
    groups: "Groups",
    createGroup: "Create Group",
    createMember: "Create User",
    manageOrganization: "Enter your organization information",
    creatingOrganization: "Create organization",
    editingOrganization: "Edit organization",
    changeYourPassword: "Change your password",
    passwordValidationDuration: "Password has 24 hours of validation",
    passwordRemember: "Remember",
    passwordChange: "Change",
    passwordConfirm: "Password Confirmation",
    PasswordsMustMatchYup: "Passwords must match",
    currentPassword: "Current Password",
    password: "Password",
    forgotPassword: "Forgot Password",
    forgotPasswordSuggestion:
      "Please enter your email to recover your password",
    name: "Name",
    lastName: "Last Name",
    phone: "Phone number",
    email: "Email",
    emailWarning: {
      first: "Please click on the email we sent you to verify your address.",
      second:
        "If you did not receive it, click click-here to resend the verification email. Don't forget to check your spam folder.",
      thirds: "If your email it is not userEmail, you can change it "
    },
    remove: "Remove",
    important: "Important",
    enter: "Enter",
    pleaseWait: "Please wait...",
    employees: "Employees",
    country: "Country",
    keyResult: "Key Result",
    subObjective: "Sub Objective",
    initialValue: "Initial Value",
    effectMetric: "Lag measure",
    effectMetrickey: "Lead measure",
    keyResultType: "Key Result Type",
    keyResultFrequence: "Key Result Frequency",
    measurementUnit: "Unit of Measurement",
    assigned: "Assigned",
    objectiveValue: "Objective Value",
    objectiveTitle: "Title",
    maxTitle: "The title cannot be longer than 254 characters",
    objectiveType: "OKR Type",
    frequency: "Frequency",
    subObjectiveTitle: "Sub Objective Title",
    subObjectiveIntro: "This Sub Objective helps his father goal",
    fathersGoal: "Fathers Objective",
    blurMsg: "To use this section, you must first save.",
    code: "Code",
    usingReferences: "Using References",
    usingIntro:
      "In case you are not familiar with this terms. Here is a small help",
    understood: "I understood",
    lessThan: "Less than",
    greaterThan: "More than",
    undifferentiatedValue: "Undifferentiated Value",
    somethingWentWrong: "Something went wrong",
    startsAt: "Starts At",
    endsAt: "Ends At",
    pickADate: "Pick a date",
    cycleRemovingConfirmation:
      "You are about of removing this cycle. Please confirm if you want to continue",
    apiKeyRemovingConfirmation:
      "You are about of removing this API key. Please confirm if you want to continue",
    cycleRemovedSuccessfully: "Cycle was removed successfully",
    apiKeyRemovedSuccessfully: "API key was removed successfully",
    generalError: "Something went wrong",
    authorizationError: "Authorization key is required",
    invitation: "Invitation",
    message: "Message",
    pending: "Pending",
    accepted: "Accepted",
    rejected: "Rejected",
    messageIntro: "Invitation message",
    invitationSearch: "Search for users",
    cancelInvitation: "Cancel invitation",
    resendInvitation: "Resend invitation",
    removeMember: "Remove user",
    onlyPending: "Only Pending",
    onlyAccepted: "Only Accepted",
    onlyRejected: "Only Rejected",
    all: "All",
    allUsers: "All users",
    loading: "Loading...",
    role: "Role",
    memberManager: "User manager",
    pickOrCreateMembers: "Invite users by their email",
    newInvitation: "New invitation",
    newInvitationIntro: "This will send an email to the invited user",
    cancellationConfirmationTitle: "Cancellation",
    cancellationConfirmationDescription:
      "You are about of cancel this invitation",
    resendConfirmationTitle: "Resend",
    resendConfirmationDescription: "You are about of resend this invitation",
    owner: "Owner",
    user: "User",
    admin: "Admin",
    objectiveRemoving: "Objective Remove",
    warning: "warning!",
    continue: "Do you want to continue without saving?",
    youAreAboutOfRemovingOneObjective: "Delete OKR?",
    removeCommitment: "Delete commitment?",
    removeNote: "Delete note?",
    pleaseConfirm: "This action cannot be undone",
    planificationGuide: "Help and OKR implementation course",
    parentObjective: "Parent Objective",
    editObjective: "Editing OKR",
    typeOfKr: "Type of KR",
    isPlanning: "To add an OKR, you must be in planning mode.",

    //modif
    ofBehavior: "of behavior",
    ofResults: "of result",
    youAreAboutOfAcceptThisObjective: "you are about of accept this objective",
    aboutOfAcceptThisObjective:
      "when accepting the objective it will be added as OMI at the end of the list",
    emptyResults: "No results",
    title: "Title",
    task: "task",
    note: "Notes",
    invitePeopleNow: "invite user now",
    industry: "industry",
    clickToPickOrDragAndDropYourXLSFile:
      "click to pick or drag and drop your XLS file",
    dropFileHere: "Drop File Here",
    or: "o",
    clickToUpload: "click To Upload",
    XLSFilesWithASizeLessThan500kb: "XLS Files With A Size Less Than 500kb",
    cellphone: "cellphone",
    closeUploader: "close Uploader",
    informationOKR: "Information OKR",
    advance: "Progress",
    advanceOKR: "Progress history",
    acceptSharedObjective: "accept Shared Objective",
    withoutParent: "Without Parent",
    New: "New",
    Delete: "Delete",
    Edit: "Edit",
    Share: "Share",
    Commitments: "Commitments",
    objectiveShare: "Share OKR",
    youHaveARequestToJoinThisOrganization:
      "you Have A Request To Join This Organization",
    memberStatus: "user status",
    moreThan500NewOrders: "more Than 500 New Orders",
    userNotAuthenticatedTitle: "User Not Authenticated",
    userNotAuthenticatedMessage: "You must enter your username and password",
    missingInformation: "Missing information",
    organizationIsMissing: "Organization is Missing",
    periodIsMissing: "Cycle is missing",
    validationError: "Validation error",
    payAttention: "Pay attention",
    youNeedObjectiveToRemove: "you need objective to remove",
    great: "great",
    objectiveSavedSuccessfully: "objective saved successfully",
    objectivePositionSavedSuccessfully: "objective position saved successfully",
    objectiveDeletedSuccessfully: "objective deleted successfully",
    bad: "error",
    noOrganizations: "Not found",
    noOrganizationsFoundWithEmail: "No organizations found with email",
    thatIsBad: "that is bad",
    TaskDeleted: "commitment deleted",
    done: "done",
    areYouSure: "are you sure",
    uploaderErrorTitle: "error uploader",
    uploaderErrorMessage: "something is wrong with de upload",
    cycleSafe: "cycle safe",
    apiKeySafe: "API key safe",
    good: "good",
    cycleDelete: "cycle delete",
    apiKeyDelete: "API key delete",
    nameGroup1: "name of the group nº 1",
    nameGroup2: "name of the group nº 2",
    nameGroup3: "name of the group nº 3",
    valueNow: "Current value",
    unitOfMeasurement: "Measure unit",
    InvitationPendingOrganizations: "Invitation Pending Organizations",
    invitationCancellationError: "Invitation cancellation error",
    invitationCancelledSuccessfully: "Invitation cancelled successfully",
    hello: "",
    messageFinal1: "has invited you to be part of",
    messageFinal2:
      "at EGG OKR, from here you will be able to achieve your strategic objectives with the OKR methodology and make your company incredible.",
    youAreAboutOfSendThisInvitation: "Sure you want to share this objective",
    report: "Report",
    rejectedInvitation: "Rejected invitation",
    acceptedInvitation: "Accepted invitation",
    savingObjectiveError: "Saving objective error",
    assignedTasks: "commitment assigned",
    pendingInvitation: "Pending invitation",
    userInvitations: "User invitations",
    updatesObjectives: "Updates objectives",
    lastLog: "Last log",
    usersInformation: "Users information",
    objectiveInformation: "ObjectiveS information",
    lastAdvanced: "Last advanced",
    memberInvitedSuccessfully: "User invited successfully",
    memberInvitedError: "Something went wrong when inviting the user",
    acceptedOur: "By registering you are accepting our",
    termAndCondition: "terms, conditions and privacy policies",
    withOutOrganization:
      "Currently you do not belong to any organization, to create one click on the button",
    withOutOkr:
      "Start by creating a highest impact objective or complete the e-learning Implementing OKR in 4 steps.",
    creatingSubObjective: "Create Target or KR",
    creatingOmi: "Create Highest Impact Goal",
    objective: "Objective",
    suggestions: "Suggestions",
    objectiveKrType: "KR Type",
    notification: "Notification",
    reject: "Reject",
    accept: "Accept",
    youWereInvitedBy: "You were invited by",
    percentage: "Percentage",
    units: "Units",
    soldUnits: "Sold units",
    startValue: "Start value",
    differentiatedStart: "Differentiated start",
    earlyEnding: "Early ending",
    termsh1: "Terms and conditions of use",
    termsh41: "Relevant information",
    termsp1:
      "It is a necessary requirement for the acquisition of the products offered on this site, that you read and accept the following Terms and Conditions that are written below. The use of our services as well as the purchase of our products.",
    termsh42: "License",
    termsp2:
      "Futuregg through its website grants a license for users to use the tools on this website in accordance with the Terms and Conditions described in this document.",
    termsh43: "unauthorized use",
    termsp3:
      "Where applicable, you may not place one of our products, modified or unmodified, on a CD, website, or any other medium and offer them for redistribution or resale of any kind.",
    termsh44: "Property",
    termsp4:
      "You may not claim intellectual or exclusive property in any of our products, modified or unmodified. All products are the property of the content providers. Unless otherwise specified, our.",
    termsh11: "Privacy Policy",
    termsp11:
      "This Privacy Policy establishes the terms in which Futuregg uses and protects the information that is provided by its users when using its website. This company is committed to the security of the data of.",
    termsh45: "Information that is collected",
    termsp5:
      "Our website may collect personal information such as: Name, contact information such as your email address and demographic information. Likewise, when necessary, specific information may be required to process an order or make a delivery or billing.",
    termsh46: "Use of the information collected",
    termsp6:
      "Our website uses the information in order to provide the best possible service, particularly to keep a record of users, of orders, if applicable, and to improve our products and services. They may be periodically sent emails through our site with special offers, new products and other advertising information that we consider relevant to you or that may provide you with some benefit, these emails They will be sent to the address you provide and may be canceled at any time.",
    termsp61:
      "Futuregg is highly committed to keeping your information secure. We use the most advanced systems and constantly update them to ensure that there is no unauthorized access.",
    termsp62:
      "If the organization so desires, it can give access to internal Futuregg staff so that they can provide suggestions about the management of its objectives, as well as recommend related entities with which to execute complementary and synergistic actions. The organization can configure this option in the configuration section. ",
    termsh47: "Cookies",
    termsp7:
      "A cookie refers to a file that is sent with the purpose of requesting permission to be stored on your computer, by accepting said file it is created and the cookie then serves to have information regarding web traffic, and also facilitates future visits to a recurring website. Another function that cookies have is that with them websites can recognize you individually and therefore provide you with the best personalized service on their website.",
    termsp71:
      "Our website uses cookies to be able to identify the pages that are visited and their frequency. This information is used only for statistical analysis and then the information is permanently deleted. You can delete cookies at any time from your computer However, cookies help provide a better service on websites, they do not give access to information from your computer or from you, unless you want it and provide it directly, visits to a website.You can accept or deny the use of cookies, however, most browsers automatically accept cookies as it serves to have a better web service. You can also change the settings on your computer to decline cookies. If you decline you may not be able to use some of our services.",
    termsh48: "Third Party Links",
    termsp8:
      "This website may contain links to other sites that may be of interest to you. Once you click on these links and leave our page, we no longer have control over the site to which you are redirected and therefore we do not We are not responsible for the terms or privacy or the protection of your data on these other third-party sites. These sites are subject to their own privacy policies, so it is recommended that you consult them to confirm that you agree with them.",
    termsh49: "Control of your personal information",
    termsp9:
      "At any time you can restrict the collection or use of personal information that is provided to our website. Each time you are asked to fill out a form, such as the user registration form, you can check or uncheck the option to receive information by email. If you have checked the option to receive our newsletter or advertising you can cancel it at any time.",
    termsp91:
      "This company will not sell, transfer or distribute personal information that is collected without your consent, unless required by a judge with a court order.",
    termsp92:
      "Futuregg reserves the right to change the terms of this Privacy Policy at any time.",
    action: "Action",
    type: "Type",
    period: "Cycle",
    taskManager: "Commitment Manager",
    ok: "Ok, got it!",
    allIsCool: "All is cool! Now you submit this form",
    objectiveDescriptions: "Enter description of the objective here",
    industria: {
      "Aeroespacial-y-Defensa": "Aerospace and Defense",
      "Agricultura-y-Alimentacion": "Agriculture and Food",
      "Alta-Tecnologia": "High Technology",
      Automotriz: "Automotive",
      "Bancos-y-Valores": "Banking and Securities",
      "Call-Center-y-Atencion-al-Cliente": "Call Center and Customer Service",
      "Casas-Inteligentes-y-Domotica": "Smart Homes and Home Automation",
      Ciberseguridad: "Cybersecurity",
      "Capacitacion-para-Empresas-y-Organizaciones":
        "Training  for Businesses and Organizations",
      "Construccion-e-Inmobiliario": "Construction and Real Estate",
      "Consultoria-y-Servicios-Profesionales":
        "Consultancy and Professional Services",
      "Contabilidad-y-Finanzas": "Accounting and Finance",
      "Deportes-y-Bienestar": "Sports and Wellness",
      "Distribucion-y-Mayorista": "Distribution and Wholesale",
      "Educacion-y-Formacion": "Education and Training",
      "Energia-Quimicos-y-Utilidades": "Energy, Chemicals and Utilities",
      "Energias-Renovables-y-Medio-Ambiente":
        "Renewable Energy and Environment",
      "Fabricacion-e-Industria": "Manufacturing and Industry",
      "Gobierno-y-Administracion-Publica":
        "Government and Public Administration",
      "Hotel-Viajes-y-Turismo": "Hotel, Travel and Tourism",
      "Informatica-Software-y-Tecnologia-de-la-Informacion-(IT)":
        "IT, Software and Information Technology",
      "Investigacion-y-Desarrollo-(I+D)": "Research and Development (R&D)",
      "Legal-y-Cumplimiento": "Legal and Compliance",
      "Logistica-y-Cadena-de-Suministro": "Logistics and Supply Chain",
      "Marketing-Publicidad-y-RRPP": "Marketing, Advertising and PR",
      "Medicina-Farmacia-Biotecnologia-y-Salud":
        "Medicine, Pharmacy, Biotechnology and Health",
      "Medios-de-Comunicacion-y-Entretenimiento": "Media and Entertainment",
      "Mineria-y-Recursos-Naturales": "Mining and Natural Resources",
      "Organizaciones-Sin-Fines-de-Lucro": "Nonprofit Organizations",
      "Outsourcing-y-Soporte-Técnico": "Outsourcing and Technical Support",
      "Retail-y-Comercio": "Retail and Trade",
      "Seguros-y-Servicios-Financieros": "Insurance and Financial Services",
      "Telecomunicaciones-y-Redes": "Telecommunications and Networks",
      "Transporte-y-Movilidad": "Transportation and Mobility",
      Otros: "Others"
    },
    saveTheChanges: "Do you want to save the changes?",
    AdmCycle: "Manage cycles",
    startExec: "Start the execution",
    execDisabledReason: "All objectives must be completed with Key Results to start execution",
    backPlanning: "Return to Planning",
    resetPasswordForm: "Password reset instructions were sent to your email.",
    startAt: "Start at",
    endAt: "End at",
    date: "Date",
    pickOrCreateTasks: "Select or create commitment",
    objectiveInvitationIntro:
      "Share this OKR with another company or organization",
    organizationSearcher: "Enter the email of the organization owner",
    lookingForOrganization: "Looking for organizations with owner email: ",
    cycleName: "Cycle name",
    apiKeyDescription: "API key description",
    newTask: "New commitment",
    editTask: "Edit commitment",
    newNote: "New Note",
    editNote: "Edit Note",
    removingConfirmationTitle: "Removing confirmation",
    newTaskIntro: "Enter the information",
    removingConfirmationDescription: "Removing confirmation",
    howStart: "How start?",
    startElearning: "Start e-learning",
    contactUs: "Contact us",
    passwordConfirmationDescription: "Enter password",
    passwordConfirmation: "Confirm password",
    enterPassword: "Enter password",
    taskToBeDone: "click to complete",
    taskCancelledSuccessfully: "Task cancelled successfully ",
    noteDeletedSuccessfully: "Note deleted successfully",
    taskDone: "commitment completed",
    theEfect: " Lag",
    behavior: "Behavior",
    result: "Result",
    organizationInvitedSuccessfully: "Organization invited successfully",
    errorDefault: "Unexpected error",
    objectiveReadyShare:
      "This objective was already shared with this organization.",
    invitationRejectedSuccessfully: "Invitation Rejected Successfully",
    objectiveInvitationError: "Objective invitation Error",
    invitationAcceptedSuccessfully: "Invitation Accepted Successfully",
    thatIsGreat: "That is great",
    OKRMeans: "Objectives and Key Results",
    OMIMeans: "Highest Impact Objective",
    OMeans: "Objectives: Represents the breakdown of objectives...",
    KRMeans:
      "They are the key results. Critical values ​​that will determine the direction of the company...",
    emailResetSend:
      "Excellent, we sent an email to your email to reset your password",
    okResetPass: "Accept, go to login",
    TaskCreatedSuccessfully: "Commitment created successfully",
    NoteCreatedSuccessfully: "Note created successfully",
    taskCreatedError: "Error creating commitment",
    noteCreatedError: "Error creating note",
    unitObjective: "Unit of measure",
    typeObjective: "Objective",
    typeKeyResult: "Key result",
    sharedInfoTitle: "Shared goal information",
    sharedWith: "Shared width",
    sharedStatus: "Status",
    sharedIdOrganization: "ID Organization",
    sharedDelete: "Unshare",
    sharedTitle: "Title",
    ownerShared: "Objective owner",
    quitShared: "Quit",
    confirmRemoveShare: "Confirm remove share",
    removeShared: "Remove  share objective ",
    with: " with ",
    uploaderSuccessTitle: "Upload success",
    uploaderSuccessMessage: "File save!",
    uploaderErrorTitleError: "Error uploading file",
    uploaderErrorMessageError: "save file error",
    finished: "Finished",
    planning: "Planning",
    running: "Running",
    status: "State",
    partner: "Partner",
    shepherd: "Shepherd",
    explanation1:
      "Differentiated start is indicated in KRs that have not yet reached their differentiated start date. This behavior occurs when configuring a KR with Frequency of only once and when the differentiated start date is less than the current date.",
    explanation2:
      "The OKR will be displayed in Green if it has a Relative Progress greater than 70%",
    explanation3:
      "The OKR will be displayed in Yellow if you have a Relative Progress between 40% and 70%",
    explanation4:
      "The OKR will be displayed in Red if it has a Relative Progress lower than 40%",
    okrSoftware: "OKR Management Software",
    freeSoftwareTitle: "Unlimited free software",
    freeSoftwareDescrp:
      "EGG OKR is free of charge and you can use it without user and organization limits.",
    freeSoftwareWhy: "If you want to know why, click here.",
    planningTitle: "Plan your objectives and key results collaboratively",
    planningDescrip:
      "Plan the quarter with your work team in person or via video call, keeping the board updated in real time for all users.",
    executeTitle: "Execute your goals and detect risks and obstacles",
    executeDescrip:
      "Analyze the progress of your OKRs from the absolute or relative point of view based on the elapsed time and visualize indicators in colors to easily find the risks and obstacles.",
    updateTitle: "Update your indicators manually or massively through Excel",
    updateDescrip:
      "Each user can update their indicators before the monitoring meeting or update them centrally through Excel files that can combine data from other systems.",
    learnTitle: "Learn how to implement OKR with video help",
    learnDescrip:
      "Access the course “Implementing OKR in 4 steps” directly from within EGG OKR.",
    shareTitle: "Share goals with other companies or organizations – Beta",
    shareDescrip:
      "Enable collaborative work with your subsidiaries, business partners and articulate sustainable development goals to achieve broader goals.",
    validationTitle: "Activating the account",
    validationDetails: "One moment, please...",
    validationErrorTitle: "validationErrorTitle",
    validationErrorDetails: "validationErrorDetails",
    single: "Only Once",
    recurrent: "Recurrent",
    firstTimeHere: "First time here?"
  },
  es: {
    addCommitment: "Agrega un compromiso...",
    addNote: "Agrega una nota...",
    alreadyInUse: "Correo electrónico ya está en uso.",
    organizationSize: {
      Micro: "Micro",
      Small: "Pequeña",
      Medium: "Mediana",
      Large: "Grande",
      Corporate: "Corporativa"
    },
    forwardedMail: "El correo fue reenviado",
    here: "aquí",
    //invite to participate email
    invitationMessages: {
      hi: "Hola",
      notRespond:
        "IMPORTANTE: Este es un mail generado automáticamente, no lo contestes.",
      ifNotExist:
        "La cuenta se generó automáticamente, cambia tu contraseña haciendo",
      ifExist: "Responde a la invitación haciendo",
      click: "click acá",
      subject: "Fuiste invitado a formar parte de una organización",
      editAccount: "Edita tu cuenta",
      changeData: "La cuenta se genera automáticamente, cambia tus datos"
    },
    //yup
    isRequired: "es un campo obligatorio",
    validEmail: "debe ser un correo electrónico válido",
    validPhone: "debe ser un número de teléfono válido",
    Accept: "Términos y condiciones",
    minUppercase: "debe contener al menos 1 letra mayúscula",
    minSymbols: "debe contener al menos 1 símbolo",
    minNumbers: "debe contener al menos 1 número",
    min: "debe tener al menos 8 caracteres",
    //
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Páginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountData: "Datos de la Cuenta",
    myAccount: "Mi Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    userData: "Datos de usuario",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Iniciar sesión",
    signUp: "Crea una cuenta gratis",
    signInSuggestion: "¿Ya tienes cuenta?",
    signUpSuggestion: "¿Eres nuevo?",
    passwordReset: "Cambio de contraseña",
    badPassword:
      "La contraseña es incorrecta. Vuelve a intentarlo o haz clic en ¿Olvidé mi contraseña? para restablecerla.",
    tryAgain: "Intenta nuevamente",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    createOrganization: "Crear Organización",
    createObjectiveButton: "Crear Objetivo de Mayor Impacto - OMI",
    createObjective: "Crear Objetivo de Mayor Impacto",
    english: "Inglés",
    spanish: "Español",
    language: "Idioma",
    users: "Usuarios",
    description: "Descripción",
    // modals
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Dirección",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicación",
    createAccount: "Crear tu Cuenta Gratis",
    activity: "Actividad",
    accomplished: "Cumplido",
    manage: "Administrar",
    previousVersion: "Versión Anterior",
    cycle: "Ciclo",
    createCycle: "Crear Ciclo",
    createApiKey: "Crear API key",
    manageCycle: "Crea o edita los ciclos OKR para tu organización",
    manageApiKey: "Crea o elimina los API keys para tu organización",
    cycleManager: "Administrador de Ciclos",
    apiKeyManager: "Administrador de API Key",
    doYouNeedHelp: "¿Necesitas ayuda?",
    organization: "Organización",
    organizationCreatedAt: "Fecha de Creación",
    organizationOwner: "Dueño de la Organización",
    organizationUserQuantity: "Cantidad de Usuarios",
    organizations: "Organizaciones",
    myOrganizations: "Mis Organizaciones",
    myReports: "Mis Reportes",
    organizationEdition: "Edición de organización",
    organizationSettings: "Configuración de la Organización",
    organizationLeaving: "Abandonando Organización",
    createdOn: "Creado en",
    lastEditedOn: "Ultima edición en",
    uploaderFor: "Cargar archivo XLSX para",
    organizationLeavingSuccessfully:
      "El la organización se ha eliminado exitosamente",
    organizationSettingsIntro:
      "Aquí puedes ajustar información general de la organización.",
    organizationSettingsLeaving:
      "Estas a punto de abandonar esta organización. Por favor confirma si deseas continuar",
    organizationPublicMembers: "Usuarios públicos dentro de la organización",
    organizationShareStatistic:
      "Compartir anónimamente mi información con fines estadísticos.",
    organizationLeave: "Abandonar",
    organizationCancel: "Cancelar",
    organizationReason: "Motivo de la organización",
    organizationStartup: "Mi organization es una Startup",
    edit: "Editar",
    back: "volver",
    save: "Guardar",
    send: "Enviar",
    sending: "Enviando...",
    cancel: "Cancelar",
    confirm: "Confirmar",
    absolute: "Al término del ciclo",
    relative: "Al día de hoy",
    progress: "Progreso",
    byName: "Por Nombre",
    byGroup: "Por Grupo",
    byCompany: "Por Compañía",
    groupsAndMembers: "Grupos y Usuarios",
    download: "Descargar XLSX",
    loadFromFile: "Cargar datos desde XLSX",
    elapsed: "Transcurrido",
    Planned: "Planificado",
    targetsNotFound: "No se han encontrado objetivos",
    terminology: "Terminología",
    turnOff: "Salir",
    manager: "Administrador",
    members: "Usuarios",
    groups: "Grupos",
    createGroup: "Crear Grupo",
    createMember: "Crear Usuario",
    manageOrganization: "Ingresa la información de tu organización.",
    creatingOrganization: "Crear organización",
    editingOrganization: "Editar organización",
    changeYourPassword: "Cambia tu contraseña",
    passwordValidationDuration:
      "La validación de la contraseña tiene una duración de 24 horas.",
    passwordRemember: "Recuerda",
    passwordChange: "Cambiar",
    passwordConfirm: "Confirma contraseña",
    PasswordsMustMatchYup: "Las contraseñas deben coincidir",
    currentPassword: "Contraseña actual",
    password: "Contraseña",
    forgotPassword: "Olvidé mi contraseña",
    forgotPasswordSuggestion:
      "Por favor ingresa tu correo electrónico para recuperar tu contraseña.",
    name: "Nombre(s)",
    lastName: "Apellido(s)",
    phone: "Número de teléfono",
    email: "Correo electrónico",
    emailWarning: {
      first:
        "Por favor haz clic en el email que te enviamos para verificar tu dirección.",
      second:
        "Si no lo recibiste, haz clic click-here para volver a enviar el email de verificación. No olvides revisar tu carpeta de no-deseados.",
      thirds: "Si tu email no es userEmail, puedes cambiarlo "
    },
    remove: "Remover",
    important: "Importante",
    enter: "Entrar",
    pleaseWait: "Por favor espera...",
    employees: "Empleados",
    country: "País",
    keyResult: "Resultado Clave",
    subObjective: "Sub-objetivo",
    initialValue: "Valor Inicial",
    effectMetric: "Métrica de efecto",
    keyResultType: "Tipo de KR",
    keyResultFrequence: "Frecuencia de KR",
    measurementUnit: "Unidad de medida",
    assigned: "Asignado",
    objectiveValue: "Valor objetivo",
    objectiveTitle: "Título",
    maxTitle: "El titulo no puede tener mas de 254 caracteres",
    subObjectiveTitle: "Título del Sub Objetivo",
    subObjectiveIntro: "Este Sub Objetivo ayuda al avance del objetivo padre",
    fathersGoal: "Objetivo padre",
    blurMsg: "Para usar esta sección primero debes guardar.",
    code: "Código",
    usingReferences: "Referencias de Uso",
    usingIntro:
      "En caso de no sentirte familiarizado con los términos y colores de los OKR, aquí tienes una pequeña ayuda",
    understood: "Entendido",
    lessThan: "Menor a",
    greaterThan: "Mayor a",
    undifferentiatedValue: "Valor indiferenciado",
    somethingWentWrong: "Ha ocurrido un inconveniente",
    startsAt: "Comienza",
    endsAt: "Finaliza",
    pickADate: "Selecciona una fecha",
    cycleRemovingConfirmation:
      "Estas a punto de eliminar este ciclo. Por favor confirma si deseas continuar",
    apiKeyRemovingConfirmation:
      "Estas a punto de eliminar este API key. Por favor confirma si deseas continuar",
    cycleRemovedSuccessfully: "El ciclo se ha eliminado exitosamente",
    apiKeyRemovedSuccessfully: "El API key se ha eliminado exitosamente",
    generalError: "Ha ocurrido un inconveniente",
    authorizationError: "Se requiere autorización",
    invitation: "Invitación",
    message: "Mensaje",
    pending: "Pendiente",
    accepted: "Aceptada",
    rejected: "Rechazada",
    messageIntro: "Mensaje de invitación a tus usuarios",
    invitationSearch: "Buscar usuarios",
    cancelInvitation: "Cancelar invitación",
    resendInvitation: "Reenviar invitacion",
    removeMember: "Eliminar usuario",
    onlyPending: "Solo Pendientes",
    onlyAccepted: "Solo Aceptados",
    onlyRejected: "Solo Rechazados",
    all: "Todos",
    allUsers: "Todos los usuarios",
    loading: "Cargando...",
    role: "Rol",
    memberManager: "Administrador de usuarios",
    pickOrCreateMembers: "Invita a usuarios por correo electrónico",
    newInvitation: "Nueva invitación",
    newInvitationIntro: "Se enviará un correo al usuario invitado",
    cancellationConfirmationTitle: "Cancelación de invitación",
    cancellationConfirmationDescription:
      "Estas a punto de cancelar esta invitación",
    resendConfirmationTitle: "Reenvio de invitación",
    resendConfirmationDescription: "Estas a punto de reenviar esta invitación",
    owner: "Propietario",
    user: "Usuario",
    admin: "Administrador",
    objectiveRemoving: "Eliminación de objetivo",
    warning: "Atención!",
    continue: "¿Desea continuar sin guardar?",
    youAreAboutOfRemovingOneObjective: "¿Eliminar OKR?",
    removeCommitment: "¿Eliminar Compromiso?",
    removeNote: "¿Eliminar nota?",
    pleaseConfirm: "Esta acción no se pude deshacer",
    //
    planificationGuide: "Ayuda y curso de implementación OKR",
    //pasar
    parentObjective: "Objetivo padre",
    editObjective: "Editando OKR",
    isPlanning: "Para poder agregar un OKR, debe estar en modo planificación.",
    typeOfKr: "Tipo de KR",
    ofBehavior: "de comportamiento",
    ofResults: "de resultado",
    youAreAboutOfAcceptThisObjective: "estás a punto de aceptar este objetivo",
    aboutOfAcceptThisObjective:
      "al aceptar el objetivo este se agregara como OMI al final de la lista",
    emptyResults: "No hay resultados",
    title: "Título",
    task: "compromiso",
    note: "Notas",
    invitePeopleNow: "Invitar usuario ahora",
    industry: "industria",
    clickToPickOrDragAndDropYourXLSFile:
      "Haga clic para elegir o arrastre y suelte su archivo XLS",
    dropFileHere: "arrastre el archivo aquí",
    or: "o",
    clickToUpload: "click para subir",
    XLSFilesWithASizeLessThan500kb:
      "Archivos XLS con un tamaño inferior a 500kb",
    cellphone: "celular",
    closeUploader: "Cerrar archivos",
    informationOKR: "Información OKR",
    advance: "Progreso",
    advanceOKR: "Historial de progreso",
    acceptSharedObjective: "Aceptar objetivo compartido",
    withoutParent: "Sin padre",
    New: "Nuevo",
    Delete: "Eliminar",
    Edit: "Editar",
    Share: "Compartir",
    Commitments: "Compromiso",
    objectiveShare: "Compartir OKR",
    youHaveARequestToJoinThisOrganization:
      "Tienes una solicitud para unirte a esta organización",
    memberStatus: "estado de usuarios",
    moreThan500NewOrders: "mas de 500 nuevas ordenes",
    userNotAuthenticatedTitle: "Usuario no autentificado",
    userNotAuthenticatedMessage: "Debes ingresar tu usuario y contraseña",
    missingInformation: "Información no encontrada",
    organizationIsMissing: "Organización no encontrada",
    periodIsMissing: "Ciclo no encontrado",
    validationError: "Error de validación",
    payAttention: "Atención",
    youNeedObjectiveToRemove: "Necesitas un objetivo para ser removido",
    great: "Correcto",
    objectiveSavedSuccessfully: "Objetivo guardado con éxito",
    objectivePositionSavedSuccessfully:
      "Se actualizó la posición correctamente",
    objectiveDeletedSuccessfully: "Okr borrado con éxito",
    bad: "error",
    noOrganizations: "No encontrado",
    noOrganizationsFoundWithEmail:
      "Ninguna organización encontrada con ese email",
    thatIsBad: "Algo esta mal",
    TaskDeleted: "se elimino el compromiso",
    done: "Hecho",
    areYouSure: "¿Estas seguro?",
    uploaderErrorTitle: "error",
    uploaderErrorMessage: "algo salio mal al subir el archivo",
    cycleSafe: "El ciclo se ha guardado exitosamente",
    apiKeySafe: "El API key se ha guardado exitosamente",
    good: "bien",
    cycleDelete: "El ciclo se ha eliminado exitosamente",
    apiKeyDelete: "El API key se ha eliminado exitosamente",
    nameGroup1: "nombre de grupo nº 1",
    nameGroup2: "nombre de grupo nº 2",
    nameGroup3: "nombre de grupo nº 3",
    valueNow: "Valor actual",
    unitOfMeasurement: "Unidad de medida",
    InvitationPendingOrganizations: "invitaciones de organizaciones pendientes",
    invitationCancellationError: "Algo salio mal a cancelar la invitación",
    invitationCancelledSuccessfully: "Invitación cancelada con éxito",
    hello: "",
    messageFinal1: "te ha invitado a formar parte de",
    messageFinal2:
      "en EGG OKR, desde aquí podrán lograr sus objetivos estratégicos con la metodología OKR y hacer que su empresa sea increíble.",
    youAreAboutOfSendThisInvitation: "Seguro desea compartir objetivo",
    report: "Reportes",
    rejectedInvitation: "Invitaciones rechazadas",
    acceptedInvitation: "Invitaciones aceptadas",
    savingObjectiveError: "Algo salio mal al guardar el objetivo",
    assignedTasks: "Compromisos asignados",
    pendingInvitation: "Invitaciones pendientes",
    userInvitations: "Invitaciones de usuarios",
    updatesObjectives: "Actualizaciones por objetivo",
    lastLog: "Ultimo ingreso",
    usersInformation: "Información de usuario",
    objectiveInformation: "Información de objetivos",
    lastAdvanced: "Ultima actualización",
    memberInvitedSuccessfully: "Usuario invitado con éxito",
    memberInvitedError: "Algo salio mal al invitar el usuario",
    acceptedOur: "Al registrarte estas aceptando nuestros",
    termAndCondition: "términos, condiciones y políticas de privacidad",
    objectiveType: "Tipo de OKR",
    frequency: "Frecuencia",
    withOutOrganization:
      "Actualmente no perteneces a ninguna organización, para crear una haz click en el botón",
    withOutOkr:
      "Comienza creando un objetivo de mayor impacto o realizado el e-learning Implementando OKR en 4 pasos.",
    creatingSubObjective: "Crear Objetivo o KR",
    creatingOmi: "Crear Objetivo de mayor impacto",
    objective: "Objetivo",
    effectMetrickey: "Métrica de causa",
    suggestions: "Sugerencias",
    objectiveKrType: "Tipo de KR",
    notification: "Notificaciones",
    reject: "Rechazar",
    accept: "Aceptar",
    youWereInvitedBy: "Fuiste invitado por",
    percentage: "Porcentaje",
    units: "Unidades",
    soldUnits: "Unidades vendidas",
    startValue: "Valor de inicio",
    differentiatedStart: "Inicio diferenciado",
    earlyEnding: "Final anticipado",
    termsh1: "Términos y Condiciones de Uso",
    termsh41: "Información relevante",
    termsp1:
      "Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la compra de nuestros productos.",
    termsh42: "Licencia",
    termsp2:
      "Futuregg a través de su sitio web concede una licencia para que los usuarios utilicen las herramientas en este sitio web de acuerdo a los Términos y Condiciones que se describen en este documento.",
    termsh43: "Uso no autorizado",
    termsp3:
      "En caso de que aplique usted no puede colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerles para la redistribución o la reventa de ningún tipo.",
    termsh44: "Propiedad",
    termsp4:
      "Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. Todos los productos son propiedad de los proveedores del contenido. En caso de que no se especifique lo contrario, nuestros productos se proporcionan sin ningún tipo de garantía, expresa o implícita. En ningún esta compañía será responsables de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.",
    termsh11: "Política de Privacidad",
    termsp11:
      "El presente Política de Privacidad establece los términos en que Futuregg usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.",
    termsh45: "Información que es recogida",
    termsp5:
      "Nuestro sitio web podrá recoger información personal por ejemplo: Nombre, información de contacto como su dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún pedido o realizar una entrega o facturación.",
    termsh46: "Uso de la información recogida",
    termsp6:
      "Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.",
    termsp61:
      "Futuregg está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.",
    termsp62:
      "En caso que la organización así lo desee, puede dar acceso a personal interno de Futuregg para que le entregue sugerencias acerca de la gestión de sus objetivos, así como recomendar entidades relacionadas con las cuales ejecutar acciones complementarias y sinérgicas. La organización puede configurar esta opción en la sección de configuración. ",
    termsh47: "Cookies",
    termsp7:
      "Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.",
    termsp71:
      "Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente, visitas a una web. Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.",
    termsh48: "Enlaces a Terceros",
    termsp8:
      "Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.",
    termsh49: "Control de su información personal",
    termsp9:
      "En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.",
    termsp91:
      "Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.",
    termsp92:
      "Futuregg Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.",
    action: "Acción",
    type: "Tipo",
    period: "Ciclo",
    taskManager: "Administrador de compromisos",
    ok: "¡Está bien, lo tengo!",
    allIsCool: "¡Todo está bien! Ahora envía este formulario",
    objectiveDescriptions: "Ingrese la descripción del objetivo aquí",
    industria: {
      "Aeroespacial-y-Defensa": "Aeroespacial y Defensa",
      "Agricultura-y-Alimentacion": "Agricultura y Alimentación",
      "Alta-Tecnologia": "Alta Tecnología",
      Automotriz: "Automotriz",
      "Bancos-y-Valores": "Bancos y Valores",
      "Call-Center-y-Atencion-al-Cliente": "Call Center y Atención al Cliente",
      "Casas-Inteligentes-y-Domotica": "Casas Inteligentes y Domótica",
      Ciberseguridad: "Ciberseguridad",
      "Capacitacion-para-Empresas-y-Organizaciones":
        "Capacitación para Empresas y Organizaciones",
      "Construccion-e-Inmobiliario": "Construcción e Inmobiliario",
      "Consultoria-y-Servicios-Profesionales":
        "Consultoría y Servicios Profesionales",
      "Contabilidad-y-Finanzas": "Contabilidad y Finanzas",
      "Deportes-y-Bienestar": "Deportes y Bienestar",
      "Distribucion-y-Mayorista": "Distribución y Mayorista",
      "Educacion-y-Formacion": "Educación y Formación",
      "Energia-Quimicos-y-Utilidades": "Energía, Químicos y Utilidades",
      "Energias-Renovables-y-Medio-Ambiente":
        "Energías Renovables y Medio Ambiente",
      "Fabricacion-e-Industria": "Fabricación e Industria",
      "Gobierno-y-Administracion-Publica": "Gobierno y Administración Pública",
      "Hotel-Viajes-y-Turismo": "Hotel, Viajes y Turismo",
      "Informatica-Software-y-Tecnologia-de-la-Informacion-(IT)":
        "Informática, Software y Tecnología de la Información (IT)",
      "Investigacion-y-Desarrollo-(I+D)": "Investigación y Desarrollo (I+D)",
      "Legal-y-Cumplimiento": "Legal y Cumplimiento",
      "Logistica-y-Cadena-de-Suministro": "Logística y Cadena de Suministro",
      "Marketing-Publicidad-y-RRPP": "Marketing, Publicidad y RRPP",
      "Medicina-Farmacia-Biotecnologia-y-Salud":
        "Medicina, Farmacia, Biotecnología y Salud",
      "Medios-de-Comunicacion-y-Entretenimiento":
        "Medios de Comunicación y Entretenimiento",
      "Mineria-y-Recursos-Naturales": "Minería y Recursos Naturales",
      "Organizaciones-Sin-Fines-de-Lucro": "Organizaciones Sin Fines de Lucro",
      "Outsourcing-y-Soporte-Técnico": "Outsourcing y Soporte Técnico",
      "Retail-y-Comercio": "Retail y Comercio",
      "Seguros-y-Servicios-Financieros": "Seguros y Servicios Financieros",
      "Telecomunicaciones-y-Redes": "Telecomunicaciones y Redes",
      "Transporte-y-Movilidad": "Transporte y Movilidad",
      Otros: "Otros"
    },
    saveTheChanges: "¿Quiere grabar los cambios?",
    AdmCycle: "Administrar ciclos",
    startExec: "Iniciar la Ejecución",
    execDisabledReason: "Se deben completar todos los objetivos con Resultados Clave para iniciar la ejecución",
    backPlanning: "Volver a Planificación",
    resetPasswordForm: "Password reset instructions were sent to your email.",
    startAt: "Comenzar el",
    endAt: "Finalizar el",
    date: "Fecha",
    pickOrCreateTasks: "Seleccionar o crear compromiso",
    objectiveInvitationIntro:
      "Comparte este OKR con otra empresa u organización",
    organizationSearcher: "Ingresa el email del propietario de la organización",
    lookingForOrganization:
      "Buscando organizaciones con email del propietario: ",
    cycleName: "Nombre del ciclo",
    apiKeyDescription: "Descripcion API key",
    newTask: "Nuevo compromiso",
    editTask: "Editar compromiso",
    newNote: "Nueva nota",
    editNote: "Editar nota",
    removingConfirmationTitle: "Confirmar borrado",
    newTaskIntro: "Rellena los campos",
    removingConfirmationDescription: "Confirmar el borrado del compromiso",
    howStart: "¿Como comenzar?",
    startElearning: "Comenzar e-learning",
    contactUs: "Contáctanos",
    passwordConfirmationDescription: "Reingresar contraseña",
    passwordConfirmation: "Confirmar contraseña",
    enterPassword: "Ingresar contraseña",
    theEfect: " de efecto",
    taskToBeDone: "Hacer clic para completar",
    taskCancelledSuccessfully: "Tarea cancelada exitosamente",
    noteDeletedSuccessfully: "Nota eliminada exitosamente",
    taskDone: "Compromiso completado",
    behavior: "Comportamiento",
    result: "Resultado",
    organizationInvitedSuccessfully: "Invitación a organización enviada",
    errorDefault: "Ocurrió un error inesperado",
    objectiveReadyShare:
      "Este objetivo ya fue compartido con esta organización.",
    invitationRejectedSuccessfully: "Invitación rechazada con éxito",
    objectiveInvitationError: "Error en la invitación objetivo",
    invitationAcceptedSuccessfully: "Invitación aceptada con éxito",
    thatIsGreat: "Excelente",
    OKRMeans: "Objetivos y resultados clave",
    OMIMeans: "Objetivo de Mayor impacto",
    OMeans: "Objetivos: Representa al desglose de objetivos...",
    KRMeans:
      "Son los resultados claves. Valores críticos que determinarán el rumbo de la empresa...",
    emailResetSend:
      "Excelente, enviamos un email a tu correo para resetear tu contraseña",
    okResetPass: "Aceptar, ir a login",
    TaskCreatedSuccessfully: "Compromiso creado con éxito",
    NoteCreatedSuccessfully: "Nota creada con éxito",
    taskCreatedError: "Error al crear compromiso",
    noteCreatedError: "Error al crear nota",
    unitObjective: "Unidad de medida",
    typeObjective: "Objetivo",
    typeKeyResult: "Resultado clave",
    sharedInfoTitle: "Información sobre objetivo compartido",
    sharedWith: "Compartido con",
    sharedStatus: "Estado",
    sharedIdOrganization: "ID Organización",
    sharedDelete: "Descompartir",
    sharedTitle: "Titulo",
    ownerShared: "Dueño objetivo",
    quitShared: "Renunciar",
    confirmRemoveShare: "¿Desea dejar de compartir?",
    removeShared: "Descompartir objetivo ",
    with: " con ",
    uploaderSuccessTitle: "Subida de archivo exitosa",
    uploaderSuccessMessage: "Su archivo se guardo",
    uploaderErrorTitleError: "Error al subir el archivo",
    uploaderErrorMessageError: "No se guardo",
    finished: "Finalizado",
    planning: "Planificando",
    running: "Ejecutando",
    status: "Estado",
    partner: "Socio",
    shepherd: "Pastor",
    explanation1:
      "Inicio diferenciado se indica en KRs que aún no llegan a la fecha de su inicio. Este comportamiento se presenta al configurar un KR con Frecuencia de una vez y cuando la fecha de inicio diferenciado es menor a la fecha actual. ",
    explanation2:
      "Se visualizará el OKR en Verde si tiene un Avance Relativo superior al 70%",
    explanation3:
      "Se visualizará el OKR en Amarillo si tiene un Avance Relativo entre el 40% y el 70%",
    explanation4:
      "Se visualizará el OKR en Rojo si tiene un Avance Relativo inferior al 40%",
    okrSoftware: "Software de gestión OKR",
    freeSoftwareTitle: "Software de uso libre sin límites",
    freeSoftwareDescrp:
      "EGG OKR no tiene costo y puedes usarlo sin límites de usuarios y organizaciones.",
    freeSoftwareWhy: "Si quieres saber por qué, haz clic aquí.",
    planningTitle:
      "Planifica tus objetivos y resultados clave colaborativamente",
    planningDescrip:
      "Realiza la planificación del trimestre con tu equipo de trabajo presencialmente o a través de videollamada manteniendo actualizado el tablero en tiempo real para todos los usuarios.",
    executeTitle: "Ejecuta tus objetivos y detecta los riesgos y obstáculos",
    executeDescrip:
      "Analiza el avance de tus OKR desde el punto de vista absoluto o relativo en función del tiempo transcurrido y visualiza indicadores en colores para encontrar los riesgos y obstaculos fácilmente.",
    updateTitle:
      "Actualiza tus indicadores manualmente o de modo masivo a través de Excel",
    updateDescrip:
      "Cada usuario pude actualizar sus indicadores antes de la reunión de seguimiento o actualízalos centralizadamente a través de archivos Excel que pueden combinar datos de otros sistemas.",
    learnTitle: "Aprende a implementar OKR con la ayuda en video",
    learnDescrip:
      "Accede a curso “Implementando OKR en 4 pasos” directamente desde dentro de EGG OKR.",
    shareTitle: "Comparte objetivos con otras empresas u organizaciones – Beta",
    shareDescrip:
      "Habilita el trabajo colaborativo con tus subsidiarias, socios de negocio y articula objetivos de desarrollo sostenible para lograr objetivos de mayor alcance.",
    validationTitle: "Activando la cuenta",
    validationDetails: "Un momento por favor...",
    validationErrorTitle: "Titulo de error de validación",
    validationErrorDetails: "Detalles de error de validación",
    single: "De una vez",
    recurrent: "Recurrente",
    firstTimeHere: "¿Primera vez por aquí?"
  }
};

const i18n = createI18n({
  legacy: false,
  fallbackLng: "en",
  locale: navigator.language.split("-")[0], // Establecer el idioma del navegador como idioma predeterminado
  globalInjection: true,
  messages
});

export default i18n;
