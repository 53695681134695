import type { DefineComponent } from 'vue';

export declare type LeadMeasureComponent = DefineComponent<{}, {}, any>;
export declare type LagMeasureComponent = DefineComponent<{}, {}, any>;

interface User {
  id: number;
  name: string;
}


interface NOTE {
  id: number | null;
  description: string;
  user: number;
  okr: number;
  created_at: string;
  edited_at: string | null;
}

interface COMMITMENT {
  id: number;
  title: string;
  description: string;
  date: string;
  user: number;
  status: number;
  okr: number | null;
  created_at: string;
  edited_at: string | null;
  end_at: string;
  start_at: string;
  deleted_at: string | null;
}

interface Objective {
  id: null | number;
  omi: boolean;
  frequency: string;
  organization: string;
  title: string;
  description: string;
  owner: User | null;
  user: User;
  period: null | string;
  type: string;
  parent: number;
  relativeAdvance: number;
  advance: number;
  code: string;
  startValue: null | number;
  targetValue: null | number;
  currentValue: null | number;
  unit: string;
  createdAt: string;
  editedAt: string;
  deletedAt: string;
  subObjectives: Objective[];
  differentiatedStart: string;
  earlyEnding: string;
  add: boolean
  addTitle: string
}

const EMPTY_OBJECTIVE: Objective = {
  id: null,
  omi: false,
  frequency: "recurrent",
  organization: "",
  title: "",
  description: "",
  owner: null,
  user: { id: 0, name: '' },
  period: null,
  type: "",
  parent: 0,
  relativeAdvance: 0,
  advance: 0,
  code: "",
  startValue: 0,
  targetValue: 100,
  currentValue: 0,
  unit: "",
  createdAt: "",
  editedAt: "",
  deletedAt: "",
  subObjectives: [],
  differentiatedStart: "",
  earlyEnding: "",
  add: false,
  addTitle: ''
};

const EMPTY_NOTE: NOTE = {
  id: null,
  description: '',
  user: 0,
  okr: 0,
  created_at: '',
  edited_at: '',
};


const EMPTY_COMMITMENT: COMMITMENT = {
  id: 0,
  title: "",
  description: "",
  date: "",
  end_at: "",
  start_at: "",
  okr: null,
  user: 0,
  status: 0,
  created_at: "",
  edited_at: null,
  deleted_at: null,
};

const OBJECTIVE_TYPE_LIST = [
  {
    id: 1,
    code: "objective",
    name: "objective",
  },
  {
    id: 2,
    code: "key_result",
    name: "keyResult",
  },
];

const UNITS = [
  {
    id: 1,
    code: "percentage",
    name: "percentage",
  },
  {
    id: 2,
    code: "units",
    name: "units",
  },
  {
    id: 3,
    code: "clp",
    name: "CLP",
  },
  {
    id: 4,
    code: "sold_units",
    name: "soldUnits",
  },
];

const FREQUENCIES = [
  {
    id: 1,
    code: "single",
    name: "single",
  },
  {
    id: 2,
    code: "recurrent",
    name: "recurrent",
  },
];

export {
  EMPTY_OBJECTIVE,
  OBJECTIVE_TYPE_LIST,
  UNITS,
  FREQUENCIES,
  EMPTY_NOTE,
  EMPTY_COMMITMENT,
  Objective,
  COMMITMENT,
  NOTE
}